<template>
    <div class="layout-device">

<!--        &lt;!&ndash;s: Device menu&ndash;&gt;-->
<!--        <div class="device-menu">-->
<!--            <div class="menu-system">-->
<!--                <div class="menu-top">-->
<!--                    <h1 class="title">设备分类</h1>-->
<!--                    <a href="javascript:" @click="onAddDevice"><Icon type="ios-add-circle-outline" /></a>-->
<!--                </div>-->
<!--                <div class="menu-input">-->
<!--                    <Input suffix="ios-search" placeholder="请输入" class="input"/>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="menu-list">-->
<!--                <div class="list-item" :key="'child1'+key" v-for="(deviceList,key) in deviceListArray">-->
<!--                    <div class="item-thead">-->
<!--                        <a href="javascript:"  @click="deviceList.visible = !deviceList.visible">-->
<!--                            <Icon :type="deviceList.visible?'ios-arrow-up':'ios-arrow-down'" class="icon" /> {{ deviceList.label }}-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="item-body" v-if="deviceList.visible">-->
<!--                        <div class="item-child" :key="'child2'+key" v-for="(children,key) in deviceList.children">-->
<!--                            <div class="child-thead" :style="children.children.length === 0?'padding-left:25px':''">-->
<!--                                <a href="javascript:"  @click="children.visible = !children.visible">-->
<!--                                    <Icon v-if="children.children.length !== 0" :type="children.visible?'ios-arrow-up':'ios-arrow-down'" class="icon" />-->
<!--                                    {{ children.label }}-->
<!--                                </a>-->
<!--                                <div class="child-system">-->
<!--                                    <a href="javascript:" @click="onUpdateDevice(children)"><Icon type="ios-create-outline" class="icon" /></a>-->
<!--                                    <a href="javascript:" @click="onDelDevice(children.value)"><Icon type="ios-remove-circle-outline" class="icon"/></a>-->
<!--                                      <a href="javascript:" @click="onChildAddDevice(children.value)"><Icon type="ios-add-circle-outline" class="icon"/></a>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="child-body" v-if="children.visible">-->
<!--                                <ul>-->
<!--                                    <li :key="'child3'+key" v-for="(child,key) in children.children">-->
<!--                                        <a href="javascript:" :class="selectedMenuId && selectedMenuId[2] === child.value?'active':''" @click="onLinks(deviceList.value,children.value,child.value)">{{ child.label }}</a>-->
<!--                                        <div class="child-system">-->
<!--                                            <a href="javascript:" @click="onUpdateDevice(child)"><Icon type="ios-create-outline" class="icon" /></a>-->
<!--                                            <a href="javascript:" @click="onDelDevice(child.value)"><Icon type="ios-remove-circle-outline" class="icon"/></a>-->
<!--                                        </div>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;e: Device menu&ndash;&gt;-->

        <!--s: Device content-->
        <div class="device-content">
            <router-view />
        </div>
        <!--e: Device content-->

        <Modal
            v-model="deviceModalVisible"
            :title="btnStatus?'添加设备分类':'修改设备分类'"
            footer-hide>
            <div class="device-modal">
                <span class="label">名称：</span>
                <Input v-model="deviceName" placeholder="请输入" style="width: 300px" />
            </div>
            <div class="process-footer">
                <Button @click="deviceModalVisible = false">取 消</Button>
                <Button type="primary" :loading="deviceLoading" @click="onAddDeviceCheck">确 定</Button>
            </div>
        </Modal>

    </div>
</template>
<script>
import Process from './process'
export default Process
</script>
<style lang="less">
@import "process";
</style>
